






















import AppHeading from '@/components/atoms/appHeading/AppHeading.vue';
import AppContent from '@/components/atoms/appContent/AppContent.vue';
import AppLink from '@/components/atoms/appLink/AppLink.vue';
import TelephoneLink from '@/components/atoms/telephoneLink/TelephoneLink.vue';
import { LinkHoverBehavior } from '@/enums/linkHoverBehavior';

export default {
    name: 'ContactBox',
    components: {
        AppHeading,
        AppContent,
        AppLink,
        TelephoneLink,
    },
    props: {
        heading: {
            type: String,
            default: null,
        },
        content: {
            type: String,
            default: null,
        },
        telephoneLinks: {
            type: Array,
            default: (): [] => [],
        },
        url: {
            type: String,
            default: null,
        },
        text: {
            type: String,
            default: null,
        },
    },
    computed: {
        linkHoverBehavior: (): string =>
            LinkHoverBehavior.textWithHoverUnderline,
    },
};
