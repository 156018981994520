











import Vue from 'vue';

export default Vue.extend({
    name: 'TelephoneLink',
    props: {
        number: {
            type: String,
            required: true,
        },
        showAsLink: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        href: function (): string | undefined {
            return this.showAsLink ? this.generateHref() : undefined;
        },
        tag: function (): string {
            return this.href ? 'a' : 'span';
        },
    },
    methods: {
        generateHref: function (): string {
            const number = this.number.replace(/[^\d+]/gi, '').trim();
            return `tel:${number}`;
        },
    },
});
