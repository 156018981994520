



















import Vue from 'vue';
import AppContent from '@/components/atoms/appContent/AppContent.vue';
import AppHeading from '@/components/atoms/appHeading/AppHeading.vue';
import Icon from '@/components/atoms/icon/Icon.vue';

export default Vue.extend({
    name: 'Stage',
    components: {
        AppContent,
        AppHeading,
        Icon,
    },
    props: {
        icon: {
            type: String,
            default: null,
        },
        heading: {
            type: String,
            default: null,
        },
        content: {
            type: String,
            default: null,
        },
    },
});
