































import Vue from 'vue';
import SpinnerArea from '@/components/molecules/spinnerArea/SpinnerArea.vue';
import { SpinnerState } from '@/enums/spinnerState';
import { MessageType } from '@/enums/messageType';
import { dialogueMessageService } from '@/services/dialogueMessageService';
import { PageContent } from '@/api/interfaces/content/app/pageContent';
import PageTemplate from '@/components/templates/pageTemplate/PageTemplate.vue';
import AppRouterLink from '@/components/atoms/appRouterLink/AppRouterLink.vue';

export default Vue.extend({
    name: 'Tools',
    components: {
        SpinnerArea,
        AppRouterLink,
        PageTemplate,
    },
    data: () => {
        return {
            spinner: SpinnerState.off,
            gridSpinner: SpinnerState.off,
            toolsContent: {
                stageContent: {
                    heading: 'Tools',
                },
            } as PageContent,
        };
    },
    created() {
        if (this.$route.query.code === 'unauthorized') {
            dialogueMessageService.show('unauthorized', MessageType.error);
        }
    },
    async mounted() {
        this.spinner = SpinnerState.opaque;

        this.spinner = SpinnerState.off;
    },
});
