




























import Vue from 'vue';
import Intro from '@/components/organisms/intro/Intro.vue';
import Stage from '@/components/organisms/stage/Stage.vue';
import ContactBox from '@/components/molecules/contactBox/ContactBox.vue';

export default Vue.extend({
    name: 'PageTemplate',
    components: {
        Intro,
        Stage,
        ContactBox,
    },
    props: {
        pageContent: {
            type: Object,
            required: true,
        },
    },
});
