
















import Vue from 'vue';

export default Vue.extend({
    name: 'AppHeading',
    props: {
        level: {
            type: Number,
            required: true,
        },
        heading: {
            type: String,
            default: null,
        },
        underline: {
            type: Boolean,
            default: false,
        },
    },
});
