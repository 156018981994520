







import Vue from 'vue';
import AppContent from '@/components/atoms/appContent/AppContent.vue';
import AppHeading from '@/components/atoms/appHeading/AppHeading.vue';

export default Vue.extend({
    name: 'Intro',
    components: {
        AppContent,
        AppHeading,
    },
    props: {
        heading: {
            type: String,
            default: null,
        },
        content: {
            type: String,
            default: null,
        },
    },
});
