




import Vue from 'vue';

export default Vue.extend({
    name: 'Icon',
    props: {
        id: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            default: null,
        },
    },
});
